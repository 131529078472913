
import { defineComponent, onMounted, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ToastService from "@/core/services/ToastService";
import { displayErrors } from "@/core/helpers/errors";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "users-form",
  emit: ["update-success"],
  components: {},
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const formRefPassword = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const showFormPassword = ref<boolean>(false);
    const formData = ref({
      firstname: "", // Solo el campo de usuario
      lastname: "",
      email: "",
      phone: "",
      username: "",
    });

    const formDataPassword = ref({
      currentpassword: "", // Solo el campo de usuario
      password: "",
      repeatpassword: "",
      username: "",
    });

    const params = {};

    const rules = ref({
      firstname: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 60,
          message: "Este campo admite máximo 60 caracteres",
          trigger: "change",
        },
      ],
      lastname: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 60,
          message: "Este campo admite máximo 60 caracteres",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 255,
          message: "Este campo admite máximo 255 caracteres",
          trigger: "change",
        },
      ],
      phone: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 30,
          message: "Este campo admite máximo 30 caracteres",
          trigger: "change",
        },
      ],
    });

    const rules_pas = ref({
      currentpassword: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 50,
          message: "Este campo admite máximo 50 caracteres",
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 50,
          message: "Este campo admite máximo 50 caracteres",
          trigger: "change",
        },
      ],
      repeatpassword: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 50,
          message: "Este campo admite máximo 50 caracteres",
          trigger: "change",
        },
        {
          validator: (rule, value, callback) => {
            if (value !== formDataPassword.value.password) {
              callback(new Error("Las contraseñas no coinciden"));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Mi Cuenta", ["Mi cuenta", "Editar"]);
      setCurrentPageActions(false, false, []);
      loadUser();
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          store
            .dispatch(Actions.UPDATE_PROFILE, formData.value)
            .then(() => {
              loading.value = false;
              if (store.state.UsersModule.success) {
                //emit("update-success");
                ToastService.show(
                  "Se ha actualizado de manera correcta",
                  "success"
                );
              }
            })
            .catch((response) => {
              displayErrors(store, "AuthModule");
              loading.value = false;
            });
        } else {
          ToastService.show(
            "Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.",
            "error"
          );
          return false;
        }
      });
    };

    const submitPassword = () => {
      if (!formRefPassword.value) {
        return;
      }
      formRefPassword.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          formDataPassword.value.username = formData.value.username;
          store
            .dispatch(Actions.UPDATE_PASSWORD_PROFILE, formDataPassword.value)
            .then(() => {
              loading.value = false;
              showFormPassword.value = false;
              if (store.state.UsersModule.success) {
                formDataPassword.value.password = "";
                formDataPassword.value.currentpassword = "";
                formDataPassword.value.repeatpassword = "";
                ToastService.show(
                  "Contraseña actualizada con éxito",
                  "success"
                );
              }
            })
            .catch((response) => {
              displayErrors(store, "UsersModule");
              loading.value = false;
            });
        } else {
          ToastService.show(
            "Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.",
            "error"
          );
          return false;
        }
      });
    };

    const loadUser = () => {
      loading.value = true;
      store
        .dispatch(Actions.LOAD_DATA_PROFILE, params)
        .then((response) => {
          if (store.state.UsersModule.success) {
            formData.value.firstname = response.firstname;
            formData.value.lastname = response.lastname;
            formData.value.email = response.email;
            formData.value.phone = response.phone;
            formData.value.username = response.username;
          }
          loading.value = false;
        })
        .catch((response) => {
          displayErrors(store, "AuthModule");
          loading.value = false;
        });
    };

    const goBack = () => {
      router.push({
        name: "user_profile",
      });
    };

    return {
      formData,
      formDataPassword,
      rules,
      rules_pas,
      submit,
      submitPassword,
      formRef,
      formRefPassword,
      loading,
      showFormPassword,
      goBack,
    };
  },
});
